import axios from "./index";

class MonitorApi {
  // static getAll = (data) => {
  //   return axios.get(`${base}/all`);
  // };

  // static createOne = (data) => {
  //   return axios.post(`${base}/create`, data);
  // };

  // static delete = (data) => {
  //   return axios.post(`${base}/delete`, data);
  // };

  static update = (data) => {
    return axios.post(`${base}/update`, data);
  };
  static download = (startDate, endDate) => {
    return axios.get(`${base}/download?startDate=${startDate}&endDate=${endDate}`, {
      responseType: "blob",
    });
  };
}

let base = "admin/monitor";

export default MonitorApi;

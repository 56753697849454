import {
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Flex,
  IconButton,
  Text,
  Tooltip,
  Button,
  Icon,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useDisclosure,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import FileDownload from "js-file-download";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  DownloadIcon,
} from "@chakra-ui/icons";
import React, { useState, useMemo, useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";

// Custom components
import Card from "components/card/Card";
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import MonitorApi from "api/monitor";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const CollapsibleRow = ({ isOpen, collapsedRows, children, ...props }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <>
      <Tr {...props}>{children}</Tr>
      {isOpen && (
        collapsedRows.map((row, index) => {
          let color = "blue";

          if (row.status == "winning") {
            color = "green";
          } else if (row.status == "failed") {
            color = "red";
          } else if (row.status == "buy now") {
            color = "green";
          } else if (row.status == "prebid") {
            color = "orange";
          }

          const isLast = index === collapsedRows.length - 1;

          const data = (
            <Badge colorScheme={color} variant="solid">
              <Text color={textColor} fontSize="sm" fontWeight="700">
                {row.status}
              </Text>
            </Badge>
          );
          return (
            <Tr key={row.id}>
              <Td borderColor={!isLast ? "transparent" : ""}></Td>
              <Td borderColor={!isLast ? "transparent" : ""}>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {`${new Date(
                    row.createdAt
                  ).toLocaleDateString()} ${new Date(
                    row.createdAt
                  ).toLocaleTimeString()}`}
                </Text>
              </Td>
              <Td borderColor={!isLast ? "transparent" : ""}>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {row.user.company_name}
                </Text>
              </Td>
              <Td borderColor={!isLast ? "transparent" : ""}>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {row.user.email}
                </Text>
              </Td>
              <Td borderColor={!isLast ? "transparent" : ""}>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {row.type.toUpperCase()}
                </Text>
              </Td>
              <Td borderColor={!isLast ? "transparent" : ""}>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {row.location}
                </Text>
              </Td>
              <Td borderColor={!isLast ? "transparent" : ""}>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {row.name.toUpperCase()}
                </Text>
              </Td>
              <Td borderColor={!isLast ? "transparent" : ""}>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {row.vin_code}
                </Text>
              </Td>
              <Td borderColor={!isLast ? "transparent" : ""}>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {row.lot_id}
                </Text>
              </Td>
              <Td borderColor={!isLast ? "transparent" : ""}>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {row.invoiceType.toUpperCase()}
                </Text>
              </Td>
              <Td borderColor={!isLast ? "transparent" : ""}>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  ${numberWithCommas(row.bid_price.toFixed(2))}
                </Text>
              </Td>
              <Td borderColor={!isLast ? "transparent" : ""}>
                {data}
              </Td>
            </Tr>
          )
        })
      )}
    </>
  );
};

// Assets
export default function UserActivityTable({ columnsData, tableData, collapsedActivities = [] }) {
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [openRows, setOpenRows] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // useEffect(() => {
  //   console.log("=========================<<<<<<<<<<<<<<<<<<<<");
  //   console.log(data);
  // }, [data])

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const handleToggle = (rowId) => {
    setOpenRows(prevOpenRows => ({
      ...prevOpenRows,
      [rowId]: !prevOpenRows[rowId],
    }));
  };


  const downloadBidHistory = () => {
    console.log(startDate, endDate);
    if (startDate <= endDate) {
      MonitorApi.download(startDate, endDate).then((res) => {
        if (res.status === 200) {
          FileDownload(res.data, `CarHunters_BidHistory_${startDate}${endDate}.pdf`);
          toast.success("Invoice downloaded successfully.");
        } else {
          console.log(res);
        }
        onClose();
      });
    }
    else {
      toast.warning("startDate and endDate are not valid. Please Check Again");
      onClose();
    }
  };

  const actionButtons = (<Button
    size="sm"
    mb="5px"
    colorScheme="green"
    onClick={onOpen}
  >
    <Icon w="20px" h="20px" me="5px" as={DownloadIcon} />
    Download
  </Button>)

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Activities
        </Text>
        {actionButtons}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {!!collapsedActivities.length && <Th borderColor={borderColor}></Th>}
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <CollapsibleRow
                isOpen={openRows[row.id]}
                collapsedRows={!collapsedActivities ? [] : collapsedActivities[row.id]}
                {...row.getRowProps()}
                key={index}
              >
                {collapsedActivities[row.id] ?
                  <Td borderColor="transparent">
                    <IconButton
                      variant="ghost"
                      onClick={() => handleToggle(row.id)}
                      icon={openRows[row.id] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                      aria-label={openRows[row.id] ? "Collapse" : "Expand"}
                    />
                  </Td> : (!!collapsedActivities.length && <Td borderColor="transparent"></Td>)}

                {row.cells.map((cell, index) => {
                  let data = "";

                  if (cell.column.Header === "STATUS") {
                    var color = "blue";

                    if (cell.value == "winning") {
                      color = "green";
                    } else if (cell.value == "failed") {
                      color = "red";
                    } else if (cell.value == "buy now") {
                      color = "green";
                    } else if (cell.value == "prebid") {
                      color = "orange";
                    }

                    data = (
                      <Badge colorScheme={color} variant="solid">
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      </Badge>
                    );
                  } else if (cell.column.Header === "CAR INFO") {
                    const upperCaseValue = cell.value ? cell.value.toUpperCase() : '';
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {upperCaseValue}
                      </Text>
                    );
                  } else if (cell.column.Header === "CREATED AT") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {`${new Date(
                          cell.value
                        ).toLocaleDateString()} ${new Date(
                          cell.value
                        ).toLocaleTimeString()}`}
                      </Text>
                    );
                  } else if (cell.column.Header === "INVOICE TYPE") {
                    const upperCaseValue = cell.value ? cell.value.toUpperCase() : '';
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {upperCaseValue}
                      </Text>
                    );
                  } else if (cell.column.Header === "BID PRICE") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        ${numberWithCommas(cell.value.toFixed(2))}
                      </Text>
                    );
                  } else if (
                    cell.column.Header === "AUCTION" ||
                    cell.column.Header === "LOCATION"
                  ) {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value.toUpperCase()}
                      </Text>
                    );
                  } else {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  }

                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </CollapsibleRow >
            );
          })}
        </Tbody>
      </Table>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Date Range</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Start Date Picker with Label */}
            <FormControl mb={4}> {/* Chakra's FormControl and margin-bottom */}
              <FormLabel>Start Date</FormLabel> {/* Start Date Label */}
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                customInput={
                  <Input
                    placeholder="Start Date"
                    style={{
                      color: 'black', // Ensure text is visible
                      backgroundColor: 'white', // Set background
                    }}
                  />
                }
              />
            </FormControl>

            {/* End Date Picker with Label */}
            <FormControl>
              <FormLabel>End Date</FormLabel> {/* End Date Label */}
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                customInput={
                  <Input
                    placeholder="End Date"
                    style={{
                      color: 'black', // Ensure text is visible
                      backgroundColor: 'white', // Set background
                    }}
                  />
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={downloadBidHistory}>
              {"Download BidHistory"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex>
          <Tooltip label="First Page">
            <IconButton
              onClick={() => gotoPage(0)}
              isDisabled={!canPreviousPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              onClick={previousPage}
              isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex alignItems="center">
          <Text flexShrink="0" mr={8}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {pageOptions.length}
            </Text>
          </Text>
          <Text flexShrink="0">Go to page:</Text>{" "}
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={pageOptions.length}
            onChange={(value) => {
              const page = value ? value - 1 : 0;
              gotoPage(page);
            }}
            defaultValue={pageIndex + 1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            w={32}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Tooltip label="Next Page">
            <IconButton
              onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              onClick={() => gotoPage(pageCount - 1)}
              isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </Card>
  );
}

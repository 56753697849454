import { Box, SimpleGrid } from "@chakra-ui/react";
import UserActivityTable from "./components/userActivityTable";
import React, { useEffect, useState } from "react";
import UserActivityApi from "api/userActivity";

export default function UserActivities() {
  var user = localStorage.getItem("user");
  user = JSON.parse(user);

  const [activities, setActivities] = useState([]);
  const [collapsedActivities, setCollapsedActivities] = useState([]);
  
  const fetchActivities = () => [
    UserActivityApi.getAll().then((res) => {
      let newActivities = [];
      let collapsed = [];
      let flag = [];
      let cnt = 0;
      res.data.forEach((activity, index) => {
        if (!flag[index]) {
          newActivities.push(activity);
          flag[index] = true;
          for (let i = index + 1; i < res.data.length; i ++) {
            if (activity.name === res.data[i].name) {
              if (!collapsed[cnt]) collapsed[cnt] = [];
              collapsed[cnt].push(res.data[i]);
              flag[i] = true;
            }
          }
          cnt ++;
        }
      });
      setCollapsedActivities(collapsed);
      setActivities(newActivities);
    }),
  ];

  var columns = [
    {
      Header: "CREATED AT",
      accessor: "createdAt",
    },
    {
      Header: "COMPANY NAME",
      accessor: "user.company_name",
    },
    {
      Header: "EMAIL",
      accessor: "user.email",
    },
    {
      Header: "AUCTION",
      accessor: "type",
    },
    {
      Header: "LOCATION",
      accessor: "location",
    },
    {
      Header: "CAR INFO",
      accessor: "name",
    },
    {
      Header: "VIN CODE",
      accessor: "vin_code",
    },
    {
      Header: "LOT ID",
      accessor: "lot_id",
    },
    {
      Header: "INVOICE TYPE",
      accessor: "invoiceType",
    },
    {
      Header: "BID PRICE",
      accessor: "bid_price",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
  ];

  if (user && user.role == "user") {
    delete columns[2];
  }


  useEffect(() => {
    fetchActivities();
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <UserActivityTable columnsData={columns} tableData={activities} collapsedActivities={collapsedActivities} />
      </SimpleGrid>
    </Box>
  );
}

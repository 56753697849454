import { Box, Editable, SimpleGrid } from "@chakra-ui/react";
import PendingInvoiceTable from "./components/invoiceTable";
import React, { useEffect, useState } from "react";
import MonitorAPI from "api/userActivity";

export default function PendingInvoices() {
  var user = localStorage.getItem("user");
  user = JSON.parse(user);

  const fetchInvoices = () => {
    MonitorAPI.getPendingInvoice().then((res) => {
      // console.log("========================     res.data ====================");
      // console.log(res.data)
      
      setInvoices(res.data.filter((item) => item.invoiceType !== "Pure Sale" && item.status === "winning" ));
      // console.log("======================== after filtering ====================");
      
      // console.log(res.data.filter((item) => item.isReserve === "yes" ));
    });
  };
  
  
  var columns = [
    {
      Header: "CREATED AT",
      accessor: "createdAt",
      editable: false,
    },
    {
      Header: "COMPANY NAME",
      accessor: "user.company_name",
      editable: false,
    },
    {
      Header: "EMAIL",
      accessor: "user.email",
      editable: false,
    },
    {
      Header: "AUCTION",
      accessor: "type",
      editable: false,
    },
    {
      Header: "LOCATION",
      accessor: "location",
      editable: false,
    },
    {
      Header: "CAR INFO",
      accessor: "name",
      editable: false,
    },
    {
      Header: "VIN CODE",
      accessor: "vin_code",
      editable: false,
    },
    {
      Header: "LOT ID",
      accessor: "lot_id",
      editable: false,
    },
    {
      Header: "BID PRICE",
      accessor: "bid_price",
      editable: false,
    },
    {
      Header: "FINAL PRICE",
      accessor: "invoice_price",
      editable: false
    },
    {
      Header: "INVOICE TYPE",
      accessor: "invoiceType",
    },
    {
      Header: "ACTION",
    },
  ];

  if (user && user.role == "user") {
    delete columns[2];
  }

  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    fetchInvoices();
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <PendingInvoiceTable
          columnsData={columns}
          tableData={invoices}
          fetchInvoices={fetchInvoices}
        />
      </SimpleGrid>
    </Box>
  );
}

/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import UserActivityApi from "api/userActivity";
import UserApi from "api/user";
import {
  MdAccountCircle,
  MdShoppingCart,
  MdDirectionsCarFilled,
  MdAttachMoney,
} from "react-icons/md";
import UserActivityTable from "../userActivity/components/userActivityTable";
import PendingInvoiceTable from "../pending-invoice/components/invoiceTable";
import UserTable from "../user/components/userTable";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function UserReports() {
  var user = localStorage.getItem("user");
  user = JSON.parse(user);

  const activityColums = [
    {
      Header: "AUCTION",
      accessor: "type",
    },
    {
      Header: "CAR INFO",
      accessor: "name",
    },
    {
      Header: "VIN CODE",
      accessor: "vin_code",
    },
    {
      Header: "BID PRICE",
      accessor: "bid_price",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
  ];

  const pendingInvoiceColumns = [
    {
      Header: "AUCTION",
      accessor: "type",
    },
    {
      Header: "CAR INFO",
      accessor: "name",
    },
    {
      Header: "VIN CODE",
      accessor: "vin_code",
    },
    {
      Header: "BID PRICE",
      accessor: "bid_price",
    },
    {
      Header: "FINAL PRICE",
      accessor: "invoice_price",
    },
  ];

  const userColumns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "ROLE",
      accessor: "role",
    },
    {
      Header: "LAST LOGIN DATE",
      accessor: "last_login_date",
    },
  ];

  const fetchActivities = () => [
    UserActivityApi.getAll().then((res) => {
      setActivities(res.data);

      var tot = 0;

      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].status != "winning") continue;

        tot += res.data[i].bid_price;
      }

      setTotalPrice(tot);
    }),
  ];

  const fetchUsers = () => [
    UserApi.getAll().then((res) => {
      setUsers(res.data);
    }),
  ];

  const fetchLots = () => [
    UserActivityApi.getLots().then((res) => {
      setLots(res.data);
    }),
  ];

  const fetchPendingInvoices = () => {
    UserActivityApi.getPendingInvoice().then((res) => {
      const filteredInvoices = res.data.filter(item => item.invoiceType && item.invoiceType.includes("Pure Sale"));
      setPendingInvoices(filteredInvoices);
    });
  };

  const [activities, setActivities] = useState([]);
  const [users, setUsers] = useState([]);
  const [lots, setLots] = useState([]);
  const [pendingInvoices, setPendingInvoices] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    fetchActivities();
    fetchUsers();
    fetchLots();
    fetchPendingInvoices();
  }, []);

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 4, lg: 4, "2xl": 4 }}
        gap="20px"
        mb="20px"
      >
        {user.role != "user" && (
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdAccountCircle}
                    color={brandColor}
                  />
                }
              />
            }
            name="Users"
            value={users.length}
          />
        )}
        {user.role == "user" && (
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdShoppingCart}
                    color={brandColor}
                  />
                }
              />
            }
            name="Pending Approvals"
            value={pendingInvoices.length}
          />
        )}
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdShoppingCart}
                  color={brandColor}
                />
              }
            />
          }
          name="Bid History"
          value={activities.length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={
                <Icon
                  w="28px"
                  h="28px"
                  as={MdDirectionsCarFilled}
                  color="white"
                />
              }
            />
          }
          name="Lot Count"
          value={lots.length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name="Payments Due"
          value={`$ ${numberWithCommas(totalPrice.toFixed(2))}`}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        {user.role != "user" && (
          <UserTable
            columnsData={userColumns}
            tableData={users}
            hasCreateButton={false}
          />
        )}
        {user.role == "user" && (
          <PendingInvoiceTable
            columnsData={pendingInvoiceColumns}
            tableData={pendingInvoices}
          />
        )}
        <UserActivityTable
          columnsData={activityColums}
          tableData={activities}
        />
      </SimpleGrid>
    </Box>
  );
}
